import Dashboard from "@/pages/dashboardPage.vue";

// Claims 
import Claims from "@/pages/claims/indexPage.vue";
import ClaimDetails from "@/pages/claims/uuid/_uuid.vue";

// Invoices
import Invoices from "@/pages/finance/InvoicesPage.vue";
import InvoiceID from "@/pages/finance/uuid/InvoicesID.vue";

import Payments from "@/pages/finance/PaymentsPage.vue";

// Withdrawals
import Withdrawals from "@/pages/finance/WithdrawalsPage.vue";
import WithdrawalDetails from "@/pages/finance/uuid/WithdrawalID.vue"

// Admins 
import Admins from "@/pages/admins/IndexPage.vue";
import CreateAdmin from "@/pages/admins/CreateNew.vue";

// Plans 
import Plans from "@/pages/plans/IndexPage.vue";
import PlanDetails from "@/pages/plans/uuid/_uuid.vue";

// Codes 
import ReferralCodes from "@/pages/codes/ReferralCodes.vue";
import SecondaryCareCodes from "@/pages/codes/SecondaryCareCodes.vue";

// Posts 
import BlogPage from "@/pages/posts/blogPage.vue";
import NewsPage from "@/pages/posts/newsPage.vue";

// Setitngs 
import Settings from "@/pages/settings/IndexPage.vue"
import CreatePost from "@/pages/posts/uuid/createPost.vue";
import ViewPost from "@/pages/posts/uuid/viewPost.vue";
import EditPost from "@/pages/posts/uuid/editPost.vue";


const router = [
  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
    meta: {
      layout: "AppDashboardLayout",
      parent: "dashboard",
      name: "dashboard",
      requiresAuth: true,
    },
  },

  {
    path: "/claims",
    name: "claims",
    component: Claims,
    meta: {
      layout: "AppDashboardLayout",
      parent: "claims",
      name: "claims",
      requiresAuth: true,
    },
  },

  {
    path: "/claim/view/:id",
    name: "claim-details",
    component: ClaimDetails,
    meta: {
      layout: "AppDashboardLayout",
      parent: "claims",
      name: "claims",
      requiresAuth: true,
      isSubPage: true
    },
  },

  {
    path: "/invoices",
    name: "invoices",
    component: Invoices,
    meta: {
      layout: "AppDashboardLayout",
      parent: "finances",
      name: "invoices",
      requiresAuth: true,
    },
  },

  {
    path: "/invoice/view/:id",
    name: "invoice",
    component: InvoiceID,
    meta: {
      layout: "AppDashboardLayout",
      parent: "finances",
      name: "invoice",
      requiresAuth: true,
      isSubPage: true
    },
  },

  {
    path: "/payments",
    name: "payments",
    component: Payments,
    meta: {
      layout: "AppDashboardLayout",
      parent: "finances",
      name: "payments",
      requiresAuth: true,
    },
  },

  {
    path: "/admins",
    name: "admins",
    component: Admins,
    meta: {
      layout: "AppDashboardLayout",
      parent: "admins",
      name: "admins",
      requiresAuth: true,
    },
  },

  {
    path: "/admins/create",
    name: "create-admin",
    component: CreateAdmin,
    meta: {
      layout: "AppDashboardLayout",
      parent: "admins",
      name: "create-new-admin",
      requiresAuth: true,
      isSubPage: true,
    },
  },

  
  {
    path: "/admin/:uuid/edit",
    name: "edit-admin",
    component: CreateAdmin,
    meta: {
      layout: "AppDashboardLayout",
      parent: "admins",
      name: "edit-admin",
      requiresAuth: true,
      isSubPage: true,
    },
  },


  {
    path: "/withdrawals",
    name: "withdrawals",
    component: Withdrawals,
    meta: {
      layout: "AppDashboardLayout",
      parent: "finances",
      name: "withdrawals",
      requiresAuth: true,
    },
  },

  {
    path: "/withdrawal/view/:id",
    name: "withdrawal-details",
    component: WithdrawalDetails,
    meta: {
      layout: "AppDashboardLayout",
      parent: "finances",
      name: "withdrawal-details",
      requiresAuth: true,
      isSubPage: true,
    },
  },

  {
    path: "/plans",
    name: "plans",
    component: Plans,
    meta: {
      layout: "AppDashboardLayout",
      parent: "plans",
      name: "plans",
      requiresAuth: true,
    },
  },

  {
    path: "/plans/:id",
    name: "plan-details",
    component: PlanDetails,
    meta: {
      layout: "AppDashboardLayout",
      parent: "plans",
      name: "plans",
      isSubPage: true,
      requiresAuth: true,
    },
  },


  // Codes 
  {
    path: "/secondary-care-codes",
    name: "secondary-care-codes",
    component: SecondaryCareCodes,
    meta: {
      layout: "AppDashboardLayout",
      parent: "secondary-codes",
      name: "secondary-care-codes",
      isSubPage: false,
      requiresAuth: true,
    },
  },

  {
    path: "/referral-codes",
    name: "referral-codes",
    component: ReferralCodes,
    meta: {
      layout: "AppDashboardLayout",
      parent: "secondary-codes",
      name: "referral-codes",
      isSubPage: false,
      requiresAuth: true,
    },
  },

  // Posts 
  // Blog
  {
    path: "/blog",
    name: "blog",
    component: BlogPage,
    meta: {
      layout: "AppDashboardLayout",
      parent: "posts",
      name: "blog",
      requiresAuth: true,
    },
  },

  {
    path: "/post/create-new",
    name: "post/create-new",
    component: CreatePost,
    meta: {
      layout: "AppDashboardLayout",
      parent: "posts",
      name: "create-post",
      requiresAuth: true,
      isSubPage: true,
    },
  },

  // News
  {
    path: "/news",
    name: "news",
    component: NewsPage,
    meta: {
      layout: "AppDashboardLayout",
      parent: "posts",
      name: "news",
      requiresAuth: true,
    },
  },

  // View SIngle Post 
  {
    path: "/post/:slug/view",
    name: "post-details",
    component: ViewPost,
    meta: {
      layout: "AppDashboardLayout",
      parent: "posts",
      name: "`view-post",
      requiresAuth: true,
      isSubPage: true,
    },
  },

  // Edit Post 
  {
    path: "/post/:slug/edit",
    name: "edit-post",
    component: EditPost,
    meta: {
      layout: "AppDashboardLayout",
      parent: "posts",
      name: "`edit-post",
      requiresAuth: true,
      isSubPage: true,
    },
  },

  // post/a-symposium-on-the-basics-of-health-management/edit?uid=1&type=event

  // Settigns 
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    meta: {
      layout: "AppDashboardLayout",
      parent: "settings",
      name: "settings",
      requiresAuth: true,
    },
  },


];

export default router;
