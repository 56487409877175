<template>
  <div>
    <div class="mb-2 text-right">
      <router-link to="/post/create-new?type=event">
        <button class="veo-btn veo-primary">Add New Event</button>
      </router-link>
    </div>
    <div class="tw-grid tw-grid-cols-3 tw-gap-4">
      <post-card v-for="item in events" :key="item.id" :post="item" @refresh="fetchPostRecords" :type="'event'" />
    </div>
  </div>
</template>

<script>
import PostCard from "@/components/utils/PostCard.vue";
export default {
  components: { PostCard },
  data() {
    return {
      events: [],
    };
  },
  methods: {
    fetchPostRecords() {
      this.$posts.getEvents().then((res) => {
        console.log(res);
        this.events = res.data;
      });
    },
  },

  created() {
    this.fetchPostRecords();
  },
};
</script>

<style></style>
