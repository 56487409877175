<template>
  <div class="tw-bg-white tw-rounded-md tw-p-8">
    <h5 class="tw-font-bold tw-text-center tw-mb-6 tw-text-[25px] tw-uppercase">
      edit {{ type }}
    </h5>
    <validation-observer v-slot="{ invalid, handleSubmit }">
      <form
        @submit.prevent="handleSubmit(onSubmit)"
        class="tw-flex tw-flex-col tw-gap-4"
      >
        <span
          v-if="Object.keys(validationErrors).length > 0"
          class="tw-mb-5 tw-block tw-text-xs tw-text-error"
          >{{ validationErrors }}</span
        >
        <div class="tw-w-full">
          <validation-provider
            name="name"
            rules="required"
            v-slot="{ dirty, invalid, errors }"
          >
            <label for="name">Title</label>
            <input
              type="text"
              name="text"
              id="input"
              v-model="title"
              placeholder="Enter Title"
              :class="{
                error: dirty && invalid,
              }"
            />
            <small
              class="tw-text-error tw-block tw-text-[10px]"
              v-for="error in validationErrors.title"
              :key="error"
              >*{{ error }}</small
            >
            <span class="tw-text-xs tw-text-error" v-if="errors">{{
              errors[0]
            }}</span>
          </validation-provider>
        </div>

        <div class="tw-w-full" v-if="type == 'event'">
          <validation-provider
            name="name"
            rules="required"
            v-slot="{ dirty, invalid, errors }"
          >
            <label for="name">Event Date</label>
            <input
              type="date"
              name="text"
              id="input"
              v-model="event_date"
              placeholder="Enter Name of Hospital"
              :class="{
                error: dirty && invalid,
              }"
            />
            <small
              class="tw-text-error tw-block tw-text-[10px]"
              v-for="error in validationErrors.event_date"
              :key="error"
              >*{{ error }}</small
            >
            <span class="tw-text-xs tw-text-error" v-if="errors">{{
              errors[0]
            }}</span>
          </validation-provider>
        </div>

        <div class="tw-w-full">
          <validation-provider
            name="description"
            rules="required"
            v-slot="{ dirty, invalid, errors }"
          >
            <label for="username">Description</label>
            <!-- <textarea
              id="input"
              v-model="description"
              placeholder="Description"
              cols="30"
              rows="10"
              :class="{
                error: dirty && invalid,
              }"
            >
            </textarea> -->
            <client-only>
              <VueEditor :class="{
                error: dirty && invalid,
              }" v-model="description" />
            </client-only>
            <small
              class="tw-text-error tw-block tw-text-[10px]"
              v-for="error in validationErrors.description"
              :key="error"
              >*{{ error }}</small
            >
            <span class="tw-text-xs tw-text-error" v-if="errors">{{
              errors[0]
            }}</span>
          </validation-provider>
        </div>

        <div class="tw-w-full">
          <!-- <validation-provider
              name="profile photo"
              rules=""
              v-slot="{ errors }"
            > -->
          <label for="username">Image</label>
          <input @change="onFileChange" type="file" id="input" />
          <!-- <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span> -->
          <small
            class="tw-text-error tw-block tw-text-[10px]"
            v-for="error in validationErrors.image"
            :key="error"
            >*{{ error }}</small
          >
          <!-- </validation-provider> -->
        </div>

        <div class="tw-mt-4 tw-text-center">
          <!-- <button
              class="veo-btn veo-primary tw-w-5/12"
            >
              <span>Add Enrollee</span>
            </button> -->

          <button
            class="veo-btn veo-primary tw-capitalize tw-w-5/12"
            v-bind:disabled="invalid || busy"
            :class="{ 'tw-bg-gray-400': invalid || busy }"
          >
            <span>{{ busy ? "..." : `Update ${type}` }}</span>
          </button>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      dataObj: {},
      title: "",
      profile_picture: "",
      description: "",
      event_date: "",
      dialogVisible: false,
      validationErrors: {},
      busy: false,
      query: this.$route.query,
      resPay: {},
      ID: this.$route.query.uid,
    };
  },

  methods: {
    onSubmit() {
      let url =
        this.type == "event" ? this.$posts.updateEvent : this.$posts.updatePost;
      let formData = new FormData();
      formData.append("title", this.title);
      formData.append("image", this.profile_picture);
      formData.append(
        "content",
        this.type !== "event" ? this.description : undefined
      );
      formData.append(
        "description",
        this.type == "event" ? this.description : undefined
      );
      formData.append(
        "event_date",
        this.type == "event" ? this.event_date : undefined
      );
      this.busy = true;
      url(this.ID, formData)
        .then((res) => {
          console.log(res, "valid");
          this.dialogVisible = true;
          this.busy = false;
          this.resPay = res;
          this.$swal.fire(
            "Edited!",
            `${this.type} deleted succesfully.`,
            "success"
          );
          this.fetchPostRecord()
        })
        .catch((err) => {
          console.log(err);
          this.validationErrors = err.data.errors;
        })
        .finally(() => {
          this.busy = false;
        });
    },

    fetchPostRecord() {
      this.$posts
        .fetchRecord(this.ID, this.type)
        .then((res) => {
          console.log(res);
          this.item = res;
          this.title = res.title;
          this.profile_picture = res.image;
          this.description = res.description || res.content;
          this.event_date = res.event_date ? res.event_date.split(" ")[0] : "";
        })
        .catch((err) => {
          console.log(err);
        });
    },

    onFileChange(e) {
      var val = e.target.files[0];
      this.profile_picture = val;
    },

    view() {
      let data = String(this.resPay).split(":").shift();
      let link = data.split(" ").join("-");
      const slug = link.toLowerCase();
      let url = `/post/${slug}/view?uid=${this.resPay.id}&type=${this.type}`;
      this.$router.push(url);
    },

    resetForm() {
      this.handleClose();
    },

    handleClose() {
      this.dialogVisible = false;
    },
  },

  created() {
    this.fetchPostRecord();
  },

  computed: {
    type() {
      return this.$route.query.type;
    },
  },
};
</script>

<style scoped>
/* El Dialog  */
.el-dialog__header {
  display: none;
}
</style>
