var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-bg-white tw-rounded-md tw-p-8"},[_c('h5',{staticClass:"tw-font-bold tw-text-center tw-mb-6 tw-text-[25px] tw-uppercase"},[_vm._v(" edit "+_vm._s(_vm.type)+" ")]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"tw-flex tw-flex-col tw-gap-4",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[(Object.keys(_vm.validationErrors).length > 0)?_c('span',{staticClass:"tw-mb-5 tw-block tw-text-xs tw-text-error"},[_vm._v(_vm._s(_vm.validationErrors))]):_vm._e(),_c('div',{staticClass:"tw-w-full"},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var dirty = ref.dirty;
var invalid = ref.invalid;
var errors = ref.errors;
return [_c('label',{attrs:{"for":"name"}},[_vm._v("Title")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.title),expression:"title"}],class:{
              error: dirty && invalid,
            },attrs:{"type":"text","name":"text","id":"input","placeholder":"Enter Title"},domProps:{"value":(_vm.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.title=$event.target.value}}}),_vm._l((_vm.validationErrors.title),function(error){return _c('small',{key:error,staticClass:"tw-text-error tw-block tw-text-[10px]"},[_vm._v("*"+_vm._s(error))])}),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),(_vm.type == 'event')?_c('div',{staticClass:"tw-w-full"},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var dirty = ref.dirty;
            var invalid = ref.invalid;
            var errors = ref.errors;
return [_c('label',{attrs:{"for":"name"}},[_vm._v("Event Date")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.event_date),expression:"event_date"}],class:{
              error: dirty && invalid,
            },attrs:{"type":"date","name":"text","id":"input","placeholder":"Enter Name of Hospital"},domProps:{"value":(_vm.event_date)},on:{"input":function($event){if($event.target.composing){ return; }_vm.event_date=$event.target.value}}}),_vm._l((_vm.validationErrors.event_date),function(error){return _c('small',{key:error,staticClass:"tw-text-error tw-block tw-text-[10px]"},[_vm._v("*"+_vm._s(error))])}),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"tw-w-full"},[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var dirty = ref.dirty;
            var invalid = ref.invalid;
            var errors = ref.errors;
return [_c('label',{attrs:{"for":"username"}},[_vm._v("Description")]),_c('client-only',[_c('VueEditor',{class:{
              error: dirty && invalid,
            },model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_vm._l((_vm.validationErrors.description),function(error){return _c('small',{key:error,staticClass:"tw-text-error tw-block tw-text-[10px]"},[_vm._v("*"+_vm._s(error))])}),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"tw-w-full"},[_c('label',{attrs:{"for":"username"}},[_vm._v("Image")]),_c('input',{attrs:{"type":"file","id":"input"},on:{"change":_vm.onFileChange}}),_vm._l((_vm.validationErrors.image),function(error){return _c('small',{key:error,staticClass:"tw-text-error tw-block tw-text-[10px]"},[_vm._v("*"+_vm._s(error))])})],2),_c('div',{staticClass:"tw-mt-4 tw-text-center"},[_c('button',{staticClass:"veo-btn veo-primary tw-capitalize tw-w-5/12",class:{ 'tw-bg-gray-400': invalid || _vm.busy },attrs:{"disabled":invalid || _vm.busy}},[_c('span',[_vm._v(_vm._s(_vm.busy ? "..." : ("Update " + _vm.type)))])])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }