<template>
  <div class="container my-4">
    <div class="tw-relative">
      <img
        :src="item.image || PLACEHOLDER"
        alt=""
        class="tw-object-cover tw-lg:h-[350px] tw-md:h-[300px] tw-h-[250px] tw-w-full tw-rounded-md tw-object-center tw-mt-3"
      />
      <span
        v-if="item.event_date"
        class="tw-absolute tw-top-2 tw-right-2 tw-bg-primary-color tw-items-center tw-text-white tw-p-2 tw-rounded-md tw-flex tw-flex-col tw-w-fit"
      >
        <span
          class="tw-leading-tighter tw-tracking-tight tw-text-sm tw-font-light"
          >{{ setMonth(item.event_date) }}</span
        >
        <span
          class="tw-text-3xl tw-tracking-tight tw-leading-tighter tw-font-semibold"
          >{{ setDate(item.event_date) }}</span
        >
      </span>
    </div>
    <div class="tw-mt-2">
      <h4 class="tw-font-bold tw-text-3xl tw-leading-tight tw-mb-2">
        {{ item.title }}
      </h4>
      <div
        class="tw-whitespace-pre-wrap tw-text-sm"
        v-html="item.content || item.description"
      ></div>
    </div>
    <div class="tw-flex tw-gap-3 tw-mt-4 tw-w-5/12">
      <router-link
        class="tw-w-full"
        :to="`/post/${splitName(item.title)}/edit?uid=${item.id}&type=${type}`"
        ><button class="veo-btn tw-bg-blue-600 veo-primary tw-w-full">
          Edit
        </button></router-link
      >
      <button
        @click="deleteItem(item.id, item.title)"
        class="veo-btn veo-primary tw-w-full tw-bg-red-600"
      >
        Delete
      </button>
    </div>
  </div>
</template>

<script>
import PLACEHOLDER from "@/assets/img/placeholder.png";
import { confirmAndDelete } from "@/plugins/deleteUtils";
export default {
  data() {
    return {
      item: {},
      ID: this.$route.query.uid,
      type: this.$route.query.type,
      PLACEHOLDER,
    };
  },

  methods: {
    setMonth(value) {
      const month = new Date(value).toLocaleString("default", {
        month: "short",
      });
      return month;
    },

    splitName(item) {
      let data = String(item).split(":").shift();
      let link = data.split(" ").join("-");
      return link.toLowerCase();
    },

    setDate(value) {
      const date = new Date(value).toLocaleString("default", {
        day: "numeric",
      });
      return date;
    },

    fetchPostRecord() {
      this.$posts
        .fetchRecord(this.ID, this.type)
        .then((res) => {
          console.log(res);
          this.item = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async deleteItem(index, name) {
      await confirmAndDelete(name, async () => {
        let url =
          this.type == "event"
            ? this.$posts.deleteEvent(index)
            : this.$posts.deletePost(index);
        try {
          // Perform actual delete logic here
          // this.items.splice(index, 1);
          const res = await url;
          console.log(res);
          return true; // Indicate success
        } catch (error) {
          console.error("Delete failed:", error);
          return false; // Indicate failure
        } finally {
          this.$router.go(-1);
        }
      });
    },
  },

  created() {
    this.fetchPostRecord();
  },
};
</script>

<style></style>
