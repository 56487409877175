<template>
  <div>
    <div class="mb-2 text-right">
      <router-link to="/post/create-new?type=blog">
          <button class="veo-btn veo-primary">Add New Post</button>
        </router-link>
    </div>
    <div class="tw-grid tw-grid-cols-3 tw-gap-4">
      <post-card v-for="item in posts" :key="item.id" :post="item" @refresh="fetchPostRecords" :type="'blog'"/>
    </div>

  </div>
</template>

<script>
import PostCard from '@/components/utils/PostCard.vue';
export default {
  components: { PostCard },
  data() {
    return {
      posts: []
    }
  },
  methods: {
    fetchPostRecords() {
      this.$posts.getPosts().then((res) => {
        console.log(res);
        this.posts = res.data
      });
    },
  },

  created() {
    this.fetchPostRecords();
  },
};
</script>

<style></style>
