// src/utils/deleteUtils.js
import Swal from "sweetalert2";

export async function confirmAndDelete(itemName, deleteCallback) {
  try {
    const result = await Swal.fire({
      title: `Are you sure you want to delete ${itemName}?`,
      text: "This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      const deleteResult = await deleteCallback(); // Execute delete function
      if (deleteResult !== false) {
        await Swal.fire("Deleted!", `${itemName} has been deleted.`, "success");
      } else {
        await Swal.fire("Cancelled", "The item was not deleted.", "info");
      }
    }
  } catch (error) {
    console.error("Error during delete:", error);
    await Swal.fire("Error!", "Something went wrong.", "error");
  }
}
