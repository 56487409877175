import $request from "@/https/axios";

const $posts = {
  //   posts
  async getPosts(params) {
    return $request
      .get(`/admin/posts`, { params })
      .then((response) => response.data);
  },

  async updatePost(params, body) {
    return $request
      .post(`admin/posts/update/${params}`, body)
      .then((response) => response.data);
  },

  async deletePost(params) {
    return $request
      .post(`admin/posts/delete/${params}`)
      .then((response) => response.data);
  },

  async addPost(body) {
    return $request
      .post(`admin/posts/add`, body)
      .then((response) => response.data);
  },

  //   posts
  async getEvents(params) {
    return $request
      .get(`/admin/events`, { params })
      .then((response) => response.data);
  },

  async updateEvent(params, body) {
    return $request
      .post(`admin/events/update/${params}`, body)
      .then((response) => response.data);
  },

  async deleteEvent(params) {
    return $request
      .post(`admin/events/delete/${params}`)
      .then((response) => response.data);
  },

  async addEvent(body) {
    return $request
      .post(`admin/events/add`, body)
      .then((response) => response.data);
  },

  // Fetch Single Record
  async fetchRecord(ID, type) {
    let url = type == "event" ? `admin/events/${ID}` : `admin/posts/${ID}`;
    return $request.get(url).then((response) => response.data);
  },
};

export default $posts;
