<template>
  <div class="tw-bg-white tw-rounded-md tw-flex tw-flex-col">
    <!-- <span class="block mb-1 text-xs text-neutral-500">{{ $formatDate(item.createdAt) }}</span> -->
    <!-- <h4 class="font-medium text-xl mb-3 leading-tight uppercase">{{ item.title }}</h4> -->
    <!-- {{ post }} -->
    <div class="tw-flex-1">
      <div class="tw-relative">
        <img
          :src="post.image || PLACEHOLDER"
          alt=""
          class="tw-object-cover tw-h-[180px] tw-rounded-tr-md tw-rounded-tl-md tw-w-full tw-object-center"
        />
        <div class="tw-absolute tw-top-2 tw-left-2">
          <el-dropdown trigger="click" placement="bottom-start">
            <span
              class="el-dropdown-link tw-bg-primary-color tw-p-1 tw-block w-fit tw-rounded-sm tw-text-white"
            >
              <i-icon icon="heroicons-solid:dots-vertical" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <div class="tw-flex tw-gap-2 tw-flex-col tw-px-4">
                <router-link
                  class="tw-w-full tw-text-[#000]"
                  :to="`/post/${splitName(post.title)}/view?uid=${
                    post.id
                  }&type=${type}`"
                >
                  View</router-link
                >
                <router-link
                  class="tw-w-full tw-text-[#000]"
                  :to="`/post/${splitName(post.title)}/edit?uid=${
                    post.id
                  }&type=${type}`"
                >
                  Edit</router-link
                >
                <button
                  @click="deleteItem(post.id, post.title)"
                  class="tw-w-full tw-text-red-600"
                >
                  Delete
                </button>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <span
          v-if="post.event_date"
          class="tw-absolute tw-top-2 tw-right-2 tw-bg-primary-color tw-items-center tw-text-white tw-p-2 tw-rounded-md tw-flex tw-flex-col tw-w-fit"
        >
          <span
            class="tw-leading-tighter tw-tracking-tight tw-text-sm tw-font-light"
            >{{ setMonth(post.event_date) }}</span
          >
          <span
            class="tw-text-3xl tw-tracking-tight tw-leading-tighter tw-font-semibold"
            >{{ setDate(post.event_date) }}</span
          >
        </span>
      </div>
      <div class="tw-p-3">
        <h4
          class="tw-font-medium tw-text-[15px] tw-leading-tight tw-capitalize tw-font-semibold"
        >
          {{ post.title }}
        </h4>
        <!-- <h4 class="text-sm leading-tight"> {{ post.content }}</h4> -->
        <div class="tw-text-sm">
          <p>
            {{ truncateHtmlContent(post.content || post.description, 100) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PLACEHOLDER from "@/assets/img/placeholder.png";
import { confirmAndDelete } from "@/plugins/deleteUtils";
export default {
  props: {
    post: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "event",
    },
  },

  data() {
    return {
      PLACEHOLDER,
    };
  },

  methods: {
    setMonth(value) {
      const month = new Date(value).toLocaleString("default", {
        month: "short",
      });
      return month;
    },

    setDate(value) {
      const date = new Date(value).toLocaleString("default", {
        day: "numeric",
      });
      return date;
    },

    async deleteItem(index, name) {
      await confirmAndDelete(name, async () => {
        let url =
          this.type == "event"
            ? this.$posts.deleteEvent(index)
            : this.$posts.deletePost(index);
        try {
          // Perform actual delete logic here
          // this.items.splice(index, 1);
          const res = await url;
          console.log(res);
          return true; // Indicate success
        } catch (error) {
          console.error("Delete failed:", error);
          return false; // Indicate failure
        }
        finally {
          this.$emit('refresh')
        }
      });
    },

    truncateHtmlContent(content, length) {
      // Optional: sanitize the content here if needed for security.
      const plainText = content.replace(/<[^>]+>/g, ""); // Strips HTML tags
      return plainText.slice(0, length) + "...";
    },

    splitName(item) {
      let data = String(item).split(":").shift();
      let link = data.split(" ").join("-");
      return link.toLowerCase();
    },
  },
};
</script>

<style></style>
